import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { Intro } from '../../components/Intro/Intro';
import Connect from '../../components/Connect';
import { StyledLink, OutboundLink } from '../../components/StyledLink';
import Projects from '../../components/Projects';
import { Section, Container, CSSGrid, Col } from '../../components/Grid';
import { RandomizerAbout } from '../../components/RandomizerAbout';
import { Box } from '../../components/Box';
import { Text } from '../../components/Text';
import { designTokens } from '../../components/Theme/designTokens';
import { ButtonLink, ButtonLinkGhost, ButtonOutboundGhost } from '../../components/Button';
import { CardOutbound, CardContainer, CardBody } from '../../components/Card';
import ImageIcon from '../../components/ImageIcon';
import ImageWrap from '../../components/ImageWrap';
import reading from '../../data/reading';
import podcasts from '../../data/podcasts';
import music from '../../data/music';
import { isLoggedIn } from '../../utilities/services/auth';
import Spotify from '../../components/Spotify';
import { Pill } from '../../components/Pill';
import { List, ListItem, ListOutbound, ListInner } from '../../components/List';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="About" keywords={['Designer', 'UI/UX', 'Tampa']} mdxType="SEO" />
    <Intro initial={{
      y: `-${designTokens.space[5]}`,
      opacity: 0
    }} animate={{
      y: 0,
      opacity: 1
    }} transition={{
      duration: 0.25,
      delay: 0.1
    }} mdxType="Intro">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1} mdxType="Col">
        <h2>Hey, I'm Ryan</h2>
        <p className="lead" style={{
              marginBottom: designTokens.space[6],
              userSelect: 'none'
            }}>
          Designer, problem solver, and {' '}
          <RandomizerAbout mdxType="RandomizerAbout" />
        </p>
      </Col>
      <Col col={1 / 3} media={{
            phone: 1
          }} mdxType="Col">
        <ImageWrap srcName="profile" mdxType="ImageWrap" />
        <div style={{
              marginTop: designTokens.space[4],
              marginBottom: designTokens.space[3],
              fontSize: designTokens.fontSizes[0]
            }}>
          <strong>Currently</strong>
        </div>
        <div style={{
              marginBottom: designTokens.space[3],
              fontSize: designTokens.fontSizes[0]
            }}>
          Digital Product Designer at <StyledLink to={'/work/masonite'} underline="true" mdxType="StyledLink">Masonite</StyledLink>
        </div>
        <div style={{
              marginBottom: designTokens.space[0],
              fontSize: designTokens.fontSizes[0]
            }}>
          Making a tool called <StyledLink to={'/work/hatch'} underline="true" mdxType="StyledLink">Hatch</StyledLink>
        </div>
        <hr style={{
              marginTop: designTokens.space[4],
              marginBottom: designTokens.space[4]
            }} />
        <Connect mdxType="Connect" />
        <div style={{
              marginTop: designTokens.space[3],
              marginBottom: designTokens.space[4]
            }}>
          <ButtonLinkGhost marginRight={designTokens.space[3]} color={({
                theme
              }) => theme.highlightedText} to={'/about/resume'} mdxType="ButtonLinkGhost">Resumé</ButtonLinkGhost>
          {isLoggedIn() ? <>
                <ButtonLinkGhost color={({
                  theme
                }) => theme.highlightedText} to={'/about/presentation'} mdxType="ButtonLinkGhost">Presentation</ButtonLinkGhost>
              </> : null}
        </div>
      </Col>
      <Col col={2 / 3} media={{
            phone: 1
          }} mdxType="Col">
        <p>
          <strong>I'm a digital product designer interested in building experiences that are logical, thoughtful, and accessible.</strong>
        </p>
        <p>
          I currently reside in Tampa,FL, where I help simplify the home remodeling experience and help build connected, IoT experiences for homeowners at <OutboundLink underline="true" href="https://www.masonite.com/" rel="noopener noreferrer" target="_blank" mdxType="OutboundLink">Masonite</OutboundLink> - a global manufacturer of doors.
        </p>
        <p>
          Previously, I helped build a problem-solving platform for payments as the first product designer at <OutboundLink underline="true" rel="noopener noreferrer" href="https://www.chargebacks911.com/" target="_blank" mdxType="OutboundLink">Chargebacks911</OutboundLink>.
        </p>
        <p>
          I’m driven to learn why people do what they do so I can create experiences that are intuitive and successful. I’ve helped rethink, prototype and design solutions to help bring empathy and simplicity into complex problems - which is a fancier way of saying I help things make sense.
          </p>
        <p>
          If you'd like to see more of what I've been working on, take a look at my <OutboundLink rel="noopener noreferrer" underline="true" href="https://codepen.io/ryanparag" target="_blank" mdxType="OutboundLink">CodePen</OutboundLink>, <OutboundLink rel="noopener noreferrer" underline="true" href="https://dribbble.com/ryanparag" target="_blank" mdxType="OutboundLink">Dribbble</OutboundLink>, and <OutboundLink rel="noopener noreferrer" underline="true" href="https://github.com/ryan-parag" target="_blank" mdxType="OutboundLink">Github</OutboundLink>. For a more in-depth look at my design process or if you have a project in mind, <OutboundLink underline="true" href="mailto:parag.ryan@gmail.com?subject=Hey, Ryan!" target="_blank" mdxType="OutboundLink">let's chat - I'm available</OutboundLink>.
        </p>
        <Spotify mdxType="Spotify" />
      </Col>
    </CSSGrid>
  </Container>
    </Intro>
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 2} media={{
            phone: 1
          }} mdxType="Col">
        <h5>What am I reading?</h5>
        <p><small>Just a few pieces of writing about design (and some tertiary stuff) that I enjoy, that I hope you might enjoy as well.</small></p>
        <CardContainer mdxType="CardContainer">
          <CardBody paddingX={'0'} paddingY={designTokens.space[2]} mdxType="CardBody">
            <List mdxType="List">
              {reading.map(book => <ListItem border key={book.title} mdxType="ListItem">
                    <ListOutbound rel="noopener noreferrer" href={book.link} target="_blank" mdxType="ListOutbound">
                      <small>
                        <Text bold marginBottom={designTokens.space[1]} mdxType="Text">{book.title}</Text>
                        <Text color={({
                          theme
                        }) => theme.grey500} mdxType="Text">{book.author}</Text>
                      </small>
                    </ListOutbound>
                  </ListItem>)}
            </List>
          </CardBody>
        </CardContainer>
      </Col>
      <Col col={1 / 2} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Podcast recommendations</h5>
        <p><small>I'm a morning person and I like to start most mornings catching up with a podcast - here are a few I've been digging:</small></p>
        <CardContainer mdxType="CardContainer">
          <CardBody paddingX={'0'} paddingY={designTokens.space[2]} mdxType="CardBody">
            <List mdxType="List">
              {podcasts.map(podcast => <ListItem border key={podcast.title} mdxType="ListItem">
                    <ListOutbound rel="noopener noreferrer" href={podcast.link} target="_blank" mdxType="ListOutbound">
                       <small>
                        <Text bold marginBottom={designTokens.space[1]} mdxType="Text">{podcast.title}</Text>
                        <Text color={({
                          theme
                        }) => theme.grey500} mdxType="Text">{podcast.description}</Text>
                      </small>
                    </ListOutbound>
                  </ListItem>)}
            </List>
          </CardBody>
        </CardContainer>
      </Col>
      <Col mdxType="Col">
        <CSSGrid mdxType="CSSGrid">
          <Col mdxType="Col">
            <h5>What am I listening to?</h5>
            <p><small>Here are a few things I've been listening to while I'm doing focused work, driving, walking around, or whatever really...</small></p>
          </Col>
          {music.map(album => <Col key={album.title} col={1 / 3} media={{
                phone: 1,
                tablet: 1 / 2
              }} mdxType="Col">
                <CardOutbound rel="noopener noreferrer" href={album.link} target="_blank" mdxType="CardOutbound">
                  <CardContainer marginBottom={designTokens.space[2]} mdxType="CardContainer">
                    <CardBody paddingY={designTokens.space[3]} paddingX={designTokens.space[3]} mdxType="CardBody">
                    <small>
                      <Text bold marginBottom={designTokens.space[1]} mdxType="Text">{album.title}</Text>
                      <Pill mdxType="Pill">{album.description}</Pill>
                    </small>
                    </CardBody>
                  </CardContainer>
                </CardOutbound>
              </Col>)}
        </CSSGrid>
      </Col>
    </CSSGrid>
  </Container>
    </Section>
    <Section section="More..." mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col mdxType="Col">
        <h5>How can I help?</h5>
        <Box marginBottom={designTokens.space[4]} mdxType="Box">
          <p>If you have a dope side project idea or want to trade project/portfolio feedback, I'd love to help out. If you want to chat about design, movies, or anything else, that’s fine too!</p>
          <p>If you're in the Tampa Bay area <i>(and even if you're not)</i>, I'd love to chat over coffee, tea, seltzer water, coke zero, whatever really ☕️ — <OutboundLink underline="true" href="mailto:parag.ryan@gmail.com?subject=Hey, Ryan!" target="_blank" mdxType="OutboundLink">let's chat</OutboundLink>!</p>
          <p style={{
                marginBottom: '0'
              }}>I'll do my best to reply quickly 👍.</p>
        </Box>
      </Col>
      <Col col={1 / 2} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Currently Learning</h5>
        <CardContainer mdxType="CardContainer">
          <CardBody paddingX={'0'} paddingY={designTokens.space[2]} mdxType="CardBody">
            <List mdxType="List">
              <ListItem border mdxType="ListItem">
                <ListInner mdxType="ListInner">
                  <small>
                    How to better utilize data and <OutboundLink rel="noopener noreferrer" underline="true" href="https://qz.com/1585165/john-maeda-on-the-importance-of-computational-design/" target="_blank" mdxType="OutboundLink">Computational Design Methods</OutboundLink>
                  </small>
                </ListInner>
              </ListItem>
              <ListItem border mdxType="ListItem">
                <ListInner mdxType="ListInner">
                  <small>
                    Design system architecture with React, Figma, styled components, and content principles
                  </small>
                </ListInner>
              </ListItem>
              <ListItem border mdxType="ListItem">
                <ListInner mdxType="ListInner">
                  <small>
                    How to drive faster than my homies on the go-kart track
                  </small>
                </ListInner>
              </ListItem>
              <ListItem border mdxType="ListItem">
                <ListInner mdxType="ListInner">
                  <small>
                    <>
                      <OutboundLink rel="noopener noreferrer" underline="true" href="https://developer.apple.com/xcode/swiftui/" target="_blank" mdxType="OutboundLink">SwiftUI</OutboundLink> / <OutboundLink rel="noopener noreferrer" underline="true" href="https://flutter.dev/" target="_blank" mdxType="OutboundLink">Flutter</OutboundLink> - to help build elegant and intuitive product experiences for mobile/watch/OS
                    </>
                  </small>
                </ListInner>
              </ListItem>
              <ListItem border mdxType="ListItem">
                <ListInner mdxType="ListInner">
                  <small>
                    How to manage a ton of tabs in my browser, but am ironically an <OutboundLink rel="noopener noreferrer" underline="true" href="https://whatis.techtarget.com/definition/inbox-zero" target="_blank" mdxType="OutboundLink">inbox zero</OutboundLink> person 🤷
                  </small>
                </ListInner>
              </ListItem>
            </List>
          </CardBody>
        </CardContainer>
      </Col>
      <Col col={1 / 2} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Portfolio</h5>
        <Box marginBottom={designTokens.space[4]} mdxType="Box">
          <div style={{
                width: '120px',
                margin: `0 0 ${designTokens.space[4]}`
              }}>
            <ImageIcon srcName="portfolio-example-icon" mdxType="ImageIcon" />
          </div>
          <p><small>Like any designer, my portfolio is really never finished and is in a constant state of having the code reworked. This is the 4th iteration of my portfolio and I put this version together with help from the following resources:</small></p>
          <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginBottom: designTokens.space[4]
              }}>
            <Pill marginRight={designTokens.space[2]} marginBottom={designTokens.space[2]} mdxType="Pill">Notion</Pill>
            <Pill marginRight={designTokens.space[2]} marginBottom={designTokens.space[2]} mdxType="Pill">Figma</Pill>
            <Pill marginRight={designTokens.space[2]} marginBottom={designTokens.space[2]} mdxType="Pill">React and Gatsby</Pill>
            <Pill marginRight={designTokens.space[2]} marginBottom={designTokens.space[2]} mdxType="Pill">Styled Components</Pill>
            <Pill marginRight={designTokens.space[2]} marginBottom={designTokens.space[2]} mdxType="Pill">Ionicons</Pill>
            <Pill marginRight={designTokens.space[2]} marginBottom={designTokens.space[2]} mdxType="Pill">Inter Font Family</Pill>
            <Pill marginRight={designTokens.space[2]} marginBottom={designTokens.space[2]} mdxType="Pill">Vercel</Pill>
            <Pill marginRight={designTokens.space[2]} marginBottom={designTokens.space[2]} mdxType="Pill">Spotify API</Pill>
            <Pill marginRight={designTokens.space[2]} marginBottom={designTokens.space[2]} mdxType="Pill">Framer Motion</Pill>
            <Pill marginRight={designTokens.space[2]} marginBottom={designTokens.space[2]} mdxType="Pill">Feedback from many nice humans</Pill>
          </div>
          <p style={{
                marginBottom: '0'
              }}>
            <small>
              <OutboundLink underline="true" href="https://notes.ryanparag.com/notes/portfolio-redesign" rel="noopener noreferrer" target="_blank" mdxType="OutboundLink">Read the post I wrote</OutboundLink> to find more about the design changes I made in this version of my portfolio!
            </small>
          </p>
        </Box>
      </Col>
      </CSSGrid>
  </Container>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      